import { useState } from 'react';
import { login } from '../api/auth.service';

const useLogin = () => {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const handleLogin = async (credentials) => {
        setLoading(true);
        setError(null);

        try {
            return await login(credentials);
        } catch (error) {
            setError(error);
            throw error;
        } finally {
            setLoading(false);
        }
    };

    return { handleLogin, loading, error };
};

export default useLogin;
