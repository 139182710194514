import React, { useContext } from "react";
import Maintenance from "../Maintenance/Maintenance";
import Stream from "../Stream/Stream";
import { AuthContext } from "../../context/AuthContext";

const Home = () => {
    const { user } = useContext(AuthContext);
    const role = user?.type;

    return (
        <>{role === "maintenance" ? <Maintenance /> : <Stream />}</>
    );
}

export default Home;