import React, {useEffect, useState} from "react";
import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField
} from '@mui/material';

const SettingsFormDialog = ({ open, setting, users, onSave, onClose, emptyForm }) => {
    const [formData, setFormData] = useState({ nameId: '', value: '', users_to_apply: [] });


    useEffect(() => {
        if (emptyForm) {
            setFormData({ nameId: '', value: '', users_to_apply: [] });
        }
    }, [emptyForm]);

    useEffect(() => {
        if (setting) {
            setFormData({ nameId: setting.name_id, value: setting.value, users_to_apply: setting.users_to_apply });
        } else {
            setFormData({ nameId: '', value: '', users_to_apply: [] });
        }
    }, [setting]);

    const handleChange = (e) => {
        // SLUG VALUE
        if (e.target.name === 'nameId') {
            e.target.value = e.target.value
                .replace(/[^a-zA-Z0-9]/g, '_')
                .toUpperCase();
        }

        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleAutoCompleteChange = (e, value) => {
        // Users to apply is an array of users ids, so we need to map the selected users to get their ids
        const usersIds = value.map((user) => user.id);
        setFormData({ ...formData, users_to_apply: usersIds });
    };

    const handleSubmit = () => {
        onSave({ ...formData, id: setting?.id });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{setting ? 'Editar configuración' : 'Agregar configuración'}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    name="nameId"
                    label="Nombre"
                    type="text"
                    fullWidth
                    value={formData.nameId}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    name="value"
                    label="Valor"
                    type="text"
                    fullWidth
                    value={formData.value}
                    onChange={handleChange}
                />
                <Autocomplete
                    sx={{ mt: 1 }}
                    value={users.filter((user) => formData.users_to_apply.includes(user.id))}
                    onChange={handleAutoCompleteChange}
                    multiple
                    id="tags-outlined"
                    options={users}
                    getOptionLabel={(option) => option.name}
                    filterSelectedOptions
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Aplica a"
                            placeholder="Usuarios"
                        />
                    )}
                />
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default SettingsFormDialog;