import AxiosInstance from "./axios.instance";

const { REACT_APP_API_BASE, REACT_APP_AUTH_REFRESH_TOKEN } = process.env;
const axiosInstance = new AxiosInstance(REACT_APP_API_BASE, REACT_APP_AUTH_REFRESH_TOKEN);

export const getUsers = async () => {
    try {
        const response = await axiosInstance.get('/users');
        const data = response?.data;
        const success = data?.success;

        if (!success) {
            const errorMessage = data?.errorMessage;
            throw new Error(errorMessage || 'Failed to fetch users');
        }

        return data;
    } catch (error) {
        throw error;
    }
}

export const createUser = async (user) => {
    try {
        const response = await axiosInstance.post('/users', user);
        const data = response?.data;
        const success = data?.success;

        if (!success) {
            const errorMessage = data?.errorMessage;
            throw new Error(errorMessage || 'Failed to create user');
        }

        return data;
    } catch (error) {
        throw error;
    }
}

export const updateUser = async (id, user) => {
    try {
        const response = await axiosInstance.put(`/users/${id}`, user);
        const data = response?.data;
        const success = data?.success;

        if (!success) {
            const errorMessage = data?.errorMessage;
            throw new Error(errorMessage || 'Failed to update user');
        }

        return data;
    } catch (error) {
        throw error;
    }
}

export const deleteUser = async (id) => {
    try {
        const response = await axiosInstance.delete(`/users/${id}`);
        const data = response?.data;
        const success = data?.success;

        if (!success) {
            const errorMessage = data?.errorMessage;
            throw new Error(errorMessage || 'Failed to delete user');
        }

        return data;
    } catch (error) {
        throw error;
    }
}