import React from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';

const ConfirmationDialog = ({ open, title, message, onConfirm, onClose }) => (
    <Dialog open={open} onClose={onClose}>
        <DialogTitle>{title}</DialogTitle>
        <DialogContent>
            {message}
        </DialogContent>
        <DialogActions>
            <Button onClick={onClose} color="primary">
                Cancelar
            </Button>
            <Button onClick={onConfirm} color="primary">
                Confirmar
            </Button>
        </DialogActions>
    </Dialog>
);

export default ConfirmationDialog;