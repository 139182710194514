import React from 'react';
import { Box, Paper, Table, TableBody, TableCell, TableContainer, TableRow, Button } from '@mui/material';
import "../../components/UserProfile/UserProfileContent.css";

const UserProfile = ({ accessData, personData, tab }) => {

    const handleSeguimientoAcceso = () => {
        console.log('Seguimiento de acceso');
    };

    const handleEnviarIncidenteAcceso = () => {
        console.log('Enviar incidente de acceso');
    };

    const handleAbrirPuerta = () => {
        console.log('Abrir puerta');
    };

    const handleCargarDatosAcceso = () => {
        console.log('Cargar datos de acceso');
    };

    const data = tab === "accessData" ? accessData : personData;

    return (
        <Box sx={{ width: '100%' }}>
            <TableContainer component={Paper}>
                <Table aria-label="Datos">
                    <TableBody>
                        {Object.entries(data).map(([key, value]) => (
                            <TableRow key={key}>
                                <TableCell component="th" scope="row">{key}</TableCell>
                                <TableCell>{value}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Box mt={2} className="button-container">
                <Button variant="contained" onClick={handleSeguimientoAcceso}>Seguimiento de Acceso</Button>
                <Button variant="contained" onClick={handleEnviarIncidenteAcceso}>Enviar Incidente de Acceso</Button>
                <Button variant="contained" onClick={handleAbrirPuerta}>Abrir Puerta</Button>
                <Button variant="contained" onClick={handleCargarDatosAcceso}>Cargar Datos de Acceso</Button>
            </Box>
        </Box>
    );
};

export default UserProfile;