import * as React from 'react';
import moment from "moment";
import Link from '@mui/material/Link';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from './Title.fragment';
import { Box } from "@mui/material";
import { Player, Controls } from '@lottiefiles/react-lottie-player';
import 'moment/locale/es-mx';

function preventDefault(event) {
    event.preventDefault();
}

export default function MainTableFragment({ data }) {
    const rows = data?.dashboardCnnDataLastActive || [];

    return (
        <React.Fragment>
            <Title>Conexiones activas</Title>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell>Id de conexion</TableCell>
                        <TableCell>Usuario</TableCell>
                        <TableCell>Conectado con</TableCell>
                        <TableCell>Inicio de conexion</TableCell>
                    </TableRow>
                </TableHead>
                {rows.length > 0 ? (
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.connection_id}>
                                <TableCell>{row.connection_id}</TableCell>
                                <TableCell>{row.username}</TableCell>
                                <TableCell>{row.connected_to_user_name}</TableCell>
                                <TableCell>{moment(row.started_at).fromNow()}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                ) : (
                    <TableBody>
                        <TableRow>
                            <TableCell colSpan={4}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                        height: 150,
                                    }}
                                >
                                    <Player
                                        autoplay
                                        loop
                                        src="https://lottie.host/d03e3b8f-e8db-4def-a825-1867b1ab6a52/Zer9R3ivTM.json"
                                        style={{ height: '150px', width: '150px' }}
                                    >
                                        <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                                    </Player>
                                </Box>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                )}
            </Table>
            <Link color="primary" href="#" onClick={preventDefault} sx={{ mt: 3 }}>
                Ver mas conexiones
            </Link>
        </React.Fragment>
    );
}