import axios from 'axios';

const { REACT_APP_AXIOS_TIMEOUT } = process.env;

class AxiosInstance {
    constructor(baseURL, refreshTokenUrl) {
        this.instance = axios.create({
            baseURL: baseURL,
            timeout: Number(REACT_APP_AXIOS_TIMEOUT) || 1000,
            headers: {
                'Content-Type': 'application/json',
            },
        });

        this.instance.interceptors.request.use(
            config => {
                const token = localStorage.getItem('accessToken');
                if (token) {
                    config.headers['Authorization'] = token;
                }
                return config;
            },
            error => {
                return Promise.reject(error);
            }
        );

        this.instance.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error.response && error.response.status === 401) {
                    // Handle 401 error, e.g., redirect to login or refresh token
                    axios({
                        method: 'post',
                        url: refreshTokenUrl,
                        headers: {
                            'Authorization': localStorage.getItem('refreshToken')
                        }
                    }).then(response => {
                        localStorage.setItem('accessToken', response?.data?.data?.accessToken);
                        // Retry the original request
                        return this.instance.request(error.config);
                    }).catch(() => {
                        // Redirect to login page
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('refreshToken');
                        localStorage.removeItem('user');
                        window.location.href = '/login';
                    });
                }
                return Promise.reject(error)
            },
        );
    }

    get(url) {
        return this.instance.get(url);
    }

    post(url, data) {
        return this.instance.post(url, data);
    }

    put(url, data) {
        return this.instance.put(url, data);
    }

    patch(url, data) {
        return this.instance.patch(url, data);
    }

    delete(url) {
        return this.instance.delete(url);
    }
}

export default AxiosInstance;