import React from 'react';
import {Box, Grid, Paper, Typography} from "@mui/material";
import { Controls, Player } from "@lottiefiles/react-lottie-player";

const VideoContent = ({ state, refs, totemSelectedName }) => {
    return (
        <Paper elevation={3} className="video-container">
            <Grid container spacing={2} style={{ flexGrow: 1 }}>
                <Grid item xs={6}>
                    <Box className="video">
                        {state.stream && (
                            <video
                                playsInline
                                muted
                                ref={refs.myVideo}
                                autoPlay
                                style={{ width: "100%", display: state.openCamera ? 'block' : 'none' }}
                            />
                        )}
                        {state.stream && !state.openCamera && (
                            <Box display="flex" justifyContent="center">
                                <Player
                                    autoplay
                                    loop
                                    src="https://lottie.host/b849bf61-3843-4239-8950-fa7573d61946/tIMM3bUhEX.json"
                                    style={{ height: '300px', width: '300px' }}
                                >
                                    <Controls visible={false} />
                                </Player>
                            </Box>
                        )}
                        <Box display="flex" justifyContent="center">
                            <Typography sx={{ color: "#ec0000", fontWeight: "bold" }} variant="h6">{state?.user?.name}</Typography>
                        </Box>
                    </Box>
                </Grid>
                <Grid item xs={6}>
                    <Box className="video">
                        {state.callAccepted && !state.callEnded ? (
                            <>
                                <video
                                    playsInline
                                    ref={refs.userVideo}
                                    autoPlay
                                    style={{ width: "100%" }}
                                />
                                <Box display="flex" justifyContent="center">
                                    <Typography sx={{ color: "#ec0000", fontWeight: "bold" }} variant="h6">{totemSelectedName}</Typography>
                                </Box>
                            </>
                        ) : (
                            <>
                                <Player
                                    autoplay
                                    loop
                                    src="https://lottie.host/d03e3b8f-e8db-4def-a825-1867b1ab6a52/Zer9R3ivTM.json"
                                    style={{ height: '300px', width: '300px' }}
                                >
                                    <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
                                </Player>
                                <Box display="flex" justifyContent="center" flexDirection="column" alignItems="center">
                                    <Typography sx={{ color: "#ec0000", fontWeight: "bold" }} variant="h6">Esperando conexión...</Typography>
                                    <Typography variant="caption" color="textSecondary">Conéctate a un Tótem para iniciar la asistencia virtual.</Typography>
                                </Box>
                            </>
                        )}
                    </Box>
                </Grid>
            </Grid>
        </Paper>
    );
};

export default VideoContent;