import React, { useState, useEffect } from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    Select,
    InputLabel
} from '@mui/material';
import Alert from '@mui/material/Alert';

const UserFormDialog = ({ open, user, onSave, onClose, emptyForm }) => {
    const [formData, setFormData] = useState({ name: '', email: '', type: 'user', password: '', confirm_password: '' });

    useEffect(() => {
        if (emptyForm) {
            setFormData({ name: '', email: '', type: 'user', password: '', confirm_password: '' });
        }
    }, [emptyForm]);

    useEffect(() => {
        if (user) {
            setFormData({ name: user.name, email: user.email, type: user.type, password: '', confirm_password: '' });
        } else {
            setFormData({ name: '', email: '', type: 'user', password: '', confirm_password: '' });
        }
    }, [user]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleConfirmPasswordChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    }

    const handleSubmit = () => {
        if (formData.password !== formData.confirm_password) {
            return;
        }
        onSave({ ...formData, id: user?.id });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>{user ? 'Editar usuario' : 'Agregar usuario'}</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    name="name"
                    label="Nombre"
                    type="text"
                    fullWidth
                    value={formData.name}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    name="email"
                    label="Email"
                    type="email"
                    fullWidth
                    value={formData.email}
                    onChange={handleChange}
                />
                <InputLabel id="type-select-label">Rol</InputLabel>
                <Select
                    labelId="type-select-label"
                    native
                    margin="dense"
                    name="type"
                    label="Rol"
                    fullWidth
                    value={formData.type}
                    onChange={handleChange}
                >
                    <option value="user">Usuario</option>
                    <option value="admin">Administrador</option>
                </Select>
                {!user && (
                    <>
                        <TextField
                            margin="dense"
                            name="password"
                            label="Contraseña"
                            type="password"
                            fullWidth
                            value={formData.password}
                            onChange={handleChange}
                        />
                        <TextField
                            margin="dense"
                            name="confirm_password"
                            label="Confirmar Contraseña"
                            type="password"
                            fullWidth
                            value={formData.confirm_password}
                            onChange={handleConfirmPasswordChange}
                        />
                        {formData.password !== formData.confirm_password && (
                            <Alert severity="error">Las contraseñas no coinciden</Alert>
                        )}
                    </>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Guardar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UserFormDialog;