import AxiosInstance from "./axios.instance";

const { REACT_APP_API_BASE, REACT_APP_AUTH_REFRESH_TOKEN } = process.env;
const axiosInstance = new AxiosInstance(REACT_APP_API_BASE, REACT_APP_AUTH_REFRESH_TOKEN);

export const getSettings = async () => {
    try {
        const response = await axiosInstance.get('/settings/users-with-names');
        const data = response?.data;
        const success = data?.success;

        if (!success) {
            const errorMessage = data?.errorMessage;
            throw new Error(errorMessage || 'Failed to fetch settings');
        }

        return data;
    } catch (error) {
        throw error;
    }
}

export const createSetting = async (setting) => {
    try {
        const response = await axiosInstance.post('/settings', setting);
        const data = response?.data;
        const success = data?.success;

        if (!success) {
            const errorMessage = data?.errorMessage;
            throw new Error(errorMessage || 'Failed to create settings');
        }

        return data;
    } catch (error) {
        throw error;
    }
}

export const updateSetting = async (id, setting) => {
    try {
        const response = await axiosInstance.put(`/settings/${id}`, setting);
        const data = response?.data;
        const success = data?.success;

        if (!success) {
            const errorMessage = data?.errorMessage;
            throw new Error(errorMessage || 'Failed to update settings');
        }

        return data;
    } catch (error) {
        throw error;
    }
}

export const deleteSetting = async (id) => {
    try {
        const response = await axiosInstance.delete(`/settings/${id}`);
        const data = response?.data;
        const success = data?.success;

        if (!success) {
            const errorMessage = data?.errorMessage;
            throw new Error(errorMessage || 'Failed to delete settings');
        }

        return data;
    } catch (error) {
        throw error;
    }
}