import React, { useState, useEffect } from 'react';
import UseAudio from "../../hooks/useAudio";
import {
    Box,
    Container,
    IconButton,
    Typography,
    Select,
    MenuItem,
    Tooltip,
    Fab,
    Tab
} from '@mui/material';
import {
    SwitchVideoRounded,
    VideocamRounded,
    VideocamOffRounded,
    MicRounded,
    MicOffRounded,
    CancelRounded
} from '@mui/icons-material';
import UserProfileContent from '../../components/UserProfile/UserProfileContent';
import CustomConfirmDialog from "../../components/Shared/CustomConfirmDialog";
import VideoContent from "../../fragments/stream/VideoContent.fragment";
import { useNotification } from "../../context/NotificationContext";
import './BackOffice.view.css';
import { TabContext, TabList, TabPanel } from "@mui/lab";

function BackOfficeView({ callbacks, state, setState, refs }) {
    const { showNotification } = useNotification();
    const notificationAlertSetting = state.user.settings.find(setting => setting.nameId === 'NOTIFICATION_ALERT');
    const notificationAlert = notificationAlertSetting?.value ? notificationAlertSetting?.value : '';
    const [totemSelected, setTotemSelected] = useState('');
    const [totemSelectedName, setTotemSelectedName] = useState('');
    const [totemList, setTotemList] = useState([]);
    const [open, setOpen] = useState(false);
    const [showFloatingButtons, setShowFloatingButtons] = useState(false);
    const [callUserButtonDisabled, setCallUserButtonDisabled] = useState(false);
    const [tabValue, setTabValue] = useState('1');
    const [playing, toggle] = UseAudio(notificationAlert);

    useEffect(() => {
        const totemRoleList = state.workStationList.filter(ws => ws.user_type === 'user');

        setTotemList(totemRoleList);
    }, [state.workStationList]);

    useEffect(() => {
        if (state.callEnded) {
            setState.setCallAccepted(false);
        }
    }, [state.callEnded, state.callAccepted, setState]);

    useEffect(() => {
        if (state.requestAssistance && !playing) {
            toggle();
        }
    }, [state.requestAssistance, toggle, playing]);

    useEffect(() => {
        console.log(state.requestAssistance);
        if (state.requestAssistance && state.assistanceName !== '') {
            showNotification('Solicitud de Asistencia', {
                body: `Favor de atender la solicitud de asistencia de ${state.assistanceName}`,
                icon: 'https://www.santander.com.mx/favicon.ico'
            });
        }
    }, [showNotification, state.requestAssistance, state.assistanceName]);

    useEffect(() => {
        // We need re-hydrate the video stream when the tab changes
        if (tabValue === '1') {
            if (state.stream) {
                refs.myVideo.current.srcObject = state.stream;
            }
        }
    }, [tabValue, refs.myVideo, state.stream]);

    useEffect(() => {
        // We need re-hydrate the user video stream when the tab changes
        if (tabValue === '1') {
            if (state.userStream) {
                refs.userVideo.current.srcObject = state.userStream;
            }
        }
    }, [tabValue, refs.userVideo, state.userStream]);

    const handleTotemSelectChanged = (event) => {
        if (event?.target?.value) {
            const totem = totemList.find(totem => totem.connection_id === event.target.value);

            setTotemSelected(event.target.value);
            setTotemSelectedName(totem.user_name);
            setState.setIdToCall(event.target.value);
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    const debounce = (func, wait) => {
        let timeout;
        return function (...args) {
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(this, args), wait);
        };
    };

    const handleScroll = debounce(() => {
        const offsetY = window.scrollY;
        if (offsetY > 40) {
            setShowFloatingButtons(true);
        } else {
            setShowFloatingButtons(false);
        }
    }, 100);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [handleScroll]);

    return (
        <Container maxWidth="lg" style={{ height: "100vh", display: "flex", flexDirection: "column", marginTop: "80px" }}>
            {!showFloatingButtons && (
                <Box sx={{ width: '100%', display: 'flex', justifyContent: 'space-between', mb: 2 }}>
                    <Box sx={{ flex: 1, minWidth: '200px' }}>
                        <Select
                            id="totem-select"
                            value={totemSelected}
                            open={open}
                            onClose={handleClose}
                            onOpen={handleOpen}
                            fullWidth
                            displayEmpty
                            onChange={handleTotemSelectChanged}
                        >
                            <MenuItem value="">
                                <em>Selecciona un dispositivo</em>
                            </MenuItem>
                            {totemList.map((totem, index) => (
                                <MenuItem key={index} value={totem.connection_id}>
                                    {totem.user_name}
                                </MenuItem>
                            ))}
                        </Select>
                    </Box>
                    <Box ml={2}>
                        {state.callAccepted && !state.callEnded ? (
                            <Tooltip title={state.idToCall ? 'Finalizar transmisión' : 'Selecciona un dispositivo'}>
                                <IconButton
                                    color="secondary"
                                    aria-label="end call"
                                    onClick={() => callbacks.leaveCall(state.idToCall)}
                                >
                                    <CancelRounded fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        ) : (
                            <Tooltip title={state.idToCall ? 'Inicar transmisión' : 'Selecciona un dispositivo'}>
                                <IconButton
                                    color="primary"
                                    aria-label="call"
                                    disabled={callUserButtonDisabled}
                                    onClick={() => {
                                        setCallUserButtonDisabled(true);
                                        callbacks.callUser(state.idToCall)
                                    }}
                                >
                                    <SwitchVideoRounded fontSize="large" />
                                </IconButton>
                            </Tooltip>
                        )}
                    </Box>
                    <Box ml={2}>
                        <Tooltip title={state.openCamera ? 'Apagar Cámara' : 'Encender Cámara'}>
                            <IconButton
                                color="primary"
                                aria-label="toggle camera"
                                onClick={() => callbacks.toggleCamera(state.idToCall)}
                            >
                                {state.openCamera ?
                                    <VideocamRounded fontSize="large" /> :
                                    <VideocamOffRounded fontSize="large" />}
                            </IconButton>
                        </Tooltip>
                    </Box>
                    <Box ml={2}>
                        <Tooltip title={state.openMicrophone ? 'Apagar Micrófono' : 'Encender Micrófono'}>
                            <IconButton
                                color="primary"
                                aria-label="toggle microphone"
                                onClick={() => callbacks.toggleMicrophone(state.idToCall)}
                            >
                                {state.openMicrophone ?
                                    <MicRounded fontSize="large" /> :
                                    <MicOffRounded fontSize="large" />}
                            </IconButton>
                        </Tooltip>
                    </Box>
                </Box>
            )}
            {showFloatingButtons && (
                <Box sx={{ position: 'fixed', bottom: 16, right: 16, display: 'flex', flexDirection: 'column', gap: 2 }}>
                    {state.callAccepted && !state.callEnded ? (
                        <Tooltip title={state.idToCall ? 'Finalizar transmisión' : 'Selecciona un dispositivo'}>
                            <Fab color="secondary" aria-label="end call" onClick={() => callbacks.leaveCall(state.idToCall)}>
                                <CancelRounded />
                            </Fab>
                        </Tooltip>
                    ) : (
                        <Tooltip title={state.idToCall ? 'Inicar transmisión' : 'Selecciona un dispositivo'}>
                            <Fab color="primary" aria-label="call" onClick={() => callbacks.callUser(state.idToCall)}>
                                <SwitchVideoRounded />
                            </Fab>
                        </Tooltip>
                    )}
                    <Tooltip title={state.openCamera ? 'Apagar Cámara' : 'Encender Cámara'}>
                        <Fab color="primary" aria-label="toggle camera" onClick={() => callbacks.toggleCamera(state.idToCall)}>
                            {state.openCamera ? <VideocamRounded /> : <VideocamOffRounded />}
                        </Fab>
                    </Tooltip>
                    <Tooltip title={state.openMicrophone ? 'Apagar Micrófono' : 'Encender Micrófono'}>
                        <Fab color="primary" aria-label="toggle microphone" onClick={() => callbacks.toggleMicrophone(state.idToCall)}>
                            {state.openMicrophone ? <MicRounded /> : <MicOffRounded />}
                        </Fab>
                    </Tooltip>
                </Box>
            )}
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleTabChange} aria-label="stream content tabs">
                        <Tab label="Video" value="1" />
                        <Tab label="Datos" value="2" />
                    </TabList>
                </Box>
                <TabPanel value={"1"}>
                    <VideoContent
                        state={state}
                        refs={refs}
                        totemSelectedName={totemSelectedName}
                    />
                </TabPanel>
                <TabPanel value={"2"}>
                    <UserProfileContent />
                </TabPanel>
            </TabContext>
            <CustomConfirmDialog
                openState={state.requestAssistance}
                handleClose={() => {
                    setState.setAssistanceName('');
                    setState.setRequestAssistance(false);
                }}
                title="Solicitud de Asistencia"
                actions={{
                    cancel: {
                        label: "Cancelar",
                        handler: () => {
                            setState.setAssistanceName('');
                            setState.setRequestAssistance(false)
                        }
                    },
                    confirm: {
                        label: "Aceptar",
                        handler: () => {
                            setState.setAssistanceName('');
                            setState.setRequestAssistance(false);
                        }
                    }
                }}
            >
                <Typography variant="body1">{`Favor de atender la solicitud de asistencia de ${state.assistanceName}`}</Typography>
            </CustomConfirmDialog>
        </Container>
    );
}

export default BackOfficeView;