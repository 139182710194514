import React, { useRef, useEffect, useContext, useState } from "react";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import io from "socket.io-client";
import TotalsCnnFragment from "../../fragments/maintenance/TotalsCnn.fragment";
import MainChartFragment from "../../fragments/maintenance/MainChart.fragment";
import MainTableFragment from "../../fragments/maintenance/MainTable.fragment";
import { AuthContext } from "../../context/AuthContext";
import process from "process";

global.process = process;

const DashboardView = () => {
    const { user } = useContext(AuthContext);
    const socketRef = useRef();
    const [dashboardCnnData, setDashboardCnnData] = useState({});
    const [maxValue, setMaxValue] = useState(0);
    const [totalToday, setTotalToday] = useState(0);

    const { id: user_id, type: user_type } = user;

    useEffect(() => {
        socketRef.current = io.connect(process.env.REACT_APP_STREAMING_API, {
            extraHeaders: {
                "user_id": user_id,
                "user_type": user_type
            }
        });

        socketRef.current.on("getDashboardCnnData", (data) => {
            const dashboardCnnDataToday = data?.dashboardCnnDataToday || [];
            const newMaxValue = getMaxValueFromData(dashboardCnnDataToday);
            const newTotalToday = dashboardCnnDataToday.reduce((total, p) => total + p.connections, 0);

            setMaxValue(newMaxValue);
            setTotalToday(newTotalToday);
            setDashboardCnnData(data);
        });

        return () => {
            socketRef.current.disconnect();
        }
    }, [user_id, user_type]);

    const getMaxValueFromData = (data) => {
        if (!data.length) return 0;
        return data.reduce((max, p) => p.connections > max ? p.connections : max, data[0].connections);
    };

    return (
        <>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 320,
                    }}
                >
                    <MainChartFragment
                        data={dashboardCnnData}
                        maxValue={maxValue}
                        totalToday={totalToday}
                    />
                </Paper>
            </Grid>
            {/* Totals cnn */}
            <Grid item xs={12} md={4} lg={3}>
                <Paper
                    sx={{
                        p: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        height: 320,
                    }}
                >
                    <TotalsCnnFragment data={dashboardCnnData} />
                </Paper>
            </Grid>
            {/* Active connections */}
            <Grid item xs={12}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column' }}>
                    <MainTableFragment data={dashboardCnnData} />
                </Paper>
            </Grid>
        </>
    );
};

export default DashboardView;