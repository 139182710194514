import React, { useState } from 'react';
import {Box, Tabs, Tab} from '@mui/material';
import UserProfile from "../../fragments/stream/UserProfileContent.fragment";
import "./UserProfileContent.css";

const UserProfileContent = () => {
    const [tabValue, setTabValue] = useState(0);
    const [accessData] = useState({
        folio: 'Sin conexión al servidor de datos.',
        edificio: 'Sin conexión al servidor de datos.',
        acceso: 'Sin conexión al servidor de datos.',
        piso: 'Sin conexión al servidor de datos.',
        arco: 'Sin conexión al servidor de datos.',
        tipoAlarma: 'Sin conexión al servidor de datos.'
    });

    const [personData] = useState({
        dni: 'Sin conexión al servidor de datos.',
        numeroTarjeta: 'Sin conexión al servidor de datos.',
        nombre: 'Sin conexión al servidor de datos.',
        apellidoPaterno: 'Sin conexión al servidor de datos.',
        apellidoMaterno: 'Sin conexión al servidor de datos.',
        empresa: 'Sin conexión al servidor de datos.',
        departamento: 'Sin conexión al servidor de datos.',
        empresaContrata: 'Sin conexión al servidor de datos.'
    });

    const handleTabChange = (event, newValue) => {
        setTabValue(newValue);
    };

    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <Tabs value={tabValue} onChange={handleTabChange} aria-label="data tabs">
                <Tab label="Datos de Acceso Físico" />
                <Tab label="Datos de la Persona" />
            </Tabs>
            {tabValue === 0 && <UserProfile
                tab="accessData"
                accessData={accessData}
                personData={personData}
            />}
            {tabValue === 1 && <UserProfile
                tab="personData"
                accessData={accessData}
                personData={personData}
            />}
        </Box>
    );
};

export default UserProfileContent;