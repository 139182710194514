import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Alert } from '@mui/material';

const ChangePwFormDialog = ({ open, user, onSave, onClose }) => {
    const [formData, setFormData] = useState({ password: '', confirm_password: '' });

    useEffect(() => {
        if (user) {
            setFormData({ password: '', confirm_password: '' });
        } else {
            setFormData({ password: '', confirm_password: '' });
        }
    }, [user]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = () => {
        if (formData.password !== formData.confirm_password) {
            return;
        }
        onSave({ ...formData, id: user?.id });
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Cambiar Contraseña</DialogTitle>
            <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    name="password"
                    label="Contraseña"
                    type="password"
                    fullWidth
                    value={formData.password}
                    onChange={handleChange}
                />
                <TextField
                    margin="dense"
                    name="confirm_password"
                    label="Confirmar Contraseña"
                    type="password"
                    fullWidth
                    value={formData.confirm_password}
                    onChange={handleChange}
                />
                {formData.password !== formData.confirm_password && (
                    <Alert severity="error">Las contraseñas no coinciden</Alert>
                )}
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Cancelar
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Cambiar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ChangePwFormDialog;