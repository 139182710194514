import * as React from 'react';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import HelpIcon from '@mui/icons-material/Help';
import Title from './Title.fragment';
import {Tooltip} from "@mui/material";

function preventDefault(event) {
    event.preventDefault();
}

export default function TotalsCnnFragment({ data }) {
    const dashboardCnnData = data?.dashboardCnnData;

    return (
      <React.Fragment>
        <Title>Total de conexiones</Title>
        <Typography component="p" variant="h4">
          {dashboardCnnData?.total}
        </Typography>
        <Typography component="h6" variant="subtitle2" color="text.secondary">
            Activas <Tooltip title={"Conexiones que están abiertas por los usuarios"}><HelpIcon fontSize="small" color="primary" /></Tooltip>
        </Typography>
        <Typography component="p" variant="h6">
          {dashboardCnnData?.total_active_connections}
        </Typography>
        <Typography component="h6" variant="subtitle2" color="text.secondary">
            Disponibles <Tooltip title={"Conexiones que están disponibles para ser usadas por los usuarios"}><HelpIcon fontSize="small" color="primary" /></Tooltip>
        </Typography>
        <Typography component="p" variant="h6">
          {dashboardCnnData?.total_available_connections}
        </Typography>
        <Typography component="h6" variant="subtitle2" color="text.secondary">
            Efectivas <Tooltip title={"Conexiones que fueron usadas y concretadas por los usuarios"}><HelpIcon fontSize="small" color="primary" /></Tooltip>
        </Typography>
        <Typography component="p" variant="h6">
          {dashboardCnnData?.total_effective_connections}
        </Typography>
        <div>
          <Link color="primary" href="#" onClick={preventDefault}>
            Ver detalles
          </Link>
        </div>
      </React.Fragment>
    );
};