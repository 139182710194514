import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Paper } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { esES } from "@mui/x-data-grid/locales";

const theme = createTheme(
    {
        palette: {
            primary: { main: '#fe0000' },
        },
    },
    esES,
);

const DataTable = ({ rows, columns }) => {
    return (
        <Paper style={{ height: 600, width: '100%' }}>
            <ThemeProvider theme={theme}>
                <DataGrid
                    loading={rows.length === 0}
                    slotProps={{
                        loadingOverlay: {
                            variant: 'skeleton',
                            noRowsVariant: 'skeleton',
                        }
                    }}
                    rows={rows}
                    columns={columns}
                    initialState={{ pagination: { paginationModel: { pageSize: 9 } } }}
                    pageSizeOptions={[9, 20, 50, 100]}
                    disableSelectionOnClick
                />
            </ThemeProvider>
        </Paper>
    );
};

export default DataTable;