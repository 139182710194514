import './App.css';
import React from 'react';
import {
    BrowserRouter as Router,
    Routes,
    Route,
    Navigate
} from 'react-router-dom';
import Login from "./components/Login/Login";
import { AuthProvider } from "./context/AuthContext";
import { NotificationProvider } from "./context/NotificationContext";
import AuthRoute from "./components/Routes/AuthRoute";
import ProtectedRoute from "./components/Routes/ProtectedRoute";
import Home from "./components/Home/Home";

function App() {
    return (
      <AuthProvider>
            <NotificationProvider>
                <Router>
                    <Routes>
                        <Route path="/login" element={<AuthRoute><Login /></AuthRoute>}/>
                        <Route path="/home" element={<ProtectedRoute element={<Home />}/>}/>
                        <Route path="/" element={<Navigate to="/home" />} />
                        <Route path="*" element={<Navigate to="/home" />}/>
                    </Routes>
                </Router>
            </NotificationProvider>
      </AuthProvider>
    );
}

export default App;
