import React, { createContext, useContext, useState, useEffect, useCallback } from 'react';

const NotificationContext = createContext();

export const NotificationProvider = ({ children }) => {
    const [notification, setNotification] = useState(null);

    useEffect(() => {
        if (notification) {
            const { title, options } = notification;
            const _notification = new Notification(title, options);

            _notification.onclick = () => {
                window.focus();
            }
        }
    }, [notification]);

    const showNotification = useCallback((title, options) => {
        if (Notification.permission === 'granted') {
            setNotification({ title, options });
        } else {
            Notification.requestPermission().then(permission => {
                if (permission === 'granted') {
                    setNotification({ title, options });
                }
            });
        }
    }, []);

    return (
        <NotificationContext.Provider value={{ showNotification }}>
            {children}
        </NotificationContext.Provider>
    );
};

export const useNotification = () => {
    return useContext(NotificationContext);
};