import { Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
const CustomConfirmDialog = ({ openState, handleClose, title, children, actions }) => {
  return (
    <Dialog
      open={openState}
      onClose={handleClose}

    >
      <DialogTitle sx={{ backgroundColor: "#ec0000", color: "#f0f4f8" }}>{title}</DialogTitle>
      <DialogContent>{children}</DialogContent>
      <DialogActions>
        <Button
            onClick={handleClose}
            sx={{
              color: "#fe0000",
                '&:hover': {
                    backgroundColor: "#f0f4f8",
                }
            }}
        >
          {actions?.cancel?.label}
        </Button>
        <Button
            onClick={actions?.confirm?.handler}
            sx={{
              backgroundColor: "#fe0000",
              color: "#f0f4f8",
                '&:hover': {
                    backgroundColor: "#ec0000",
                }
            }}
        >
          {actions?.confirm?.label}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CustomConfirmDialog;