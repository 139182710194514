import * as React from 'react';
import { useTheme } from '@mui/material/styles';
import { LineChart, axisClasses } from '@mui/x-charts';

import Title from './Title.fragment';

export default function MainChartFragment({ data, maxValue, totalToday }) {
    const dashboardCnnDataToday = data?.dashboardCnnDataToday || [];
    const theme = useTheme();

    return (
        <React.Fragment>
            <Title>Hoy ({totalToday})</Title>
            <div style={{ width: '100%', flexGrow: 1, overflow: 'hidden' }}>
                <LineChart
                    loading={dashboardCnnDataToday.length === 0}
                    slotProps={{
                        loadingOverlay: { message: 'Esperando conexiones...' },
                    }}
                    dataset={dashboardCnnDataToday}
                    margin={{
                        top: 16,
                        right: 20,
                        left: 70,
                        bottom: 40,
                    }}
                    xAxis={[
                        {
                            scaleType: 'point',
                            dataKey: 'connection_time',
                            tickNumber: 1,
                            tickLabelStyle: theme.typography.body2,
                            labelStyle: {
                                ...theme.typography.body1,
                                fill: theme.palette.text.primary,
                            },
                            label: 'Hora',
                        },
                    ]}
                    yAxis={[
                        {
                            label: 'Conexiones (cantidad)',
                            labelStyle: {
                                ...theme.typography.body1,
                                fill: theme.palette.text.primary,
                            },
                            tickLabelStyle: theme.typography.body2,
                            max: maxValue + 1,
                            tickNumber: 1,
                        },
                    ]}
                    series={[
                        {
                            dataKey: 'connections',
                            showMark: true,
                            color: theme.palette.primary.light,
                        },
                    ]}
                    sx={{
                        [`.${axisClasses.root} line`]: { stroke: theme.palette.text.secondary },
                        [`.${axisClasses.root} text`]: { fill: theme.palette.text.secondary },
                        [`& .${axisClasses.left} .${axisClasses.label}`]: {
                            transform: 'translateX(-25px)',
                        },
                    }}
                />
            </div>
        </React.Fragment>
    );
}