import React, { useEffect, useRef, useState } from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

const theme = createTheme();

const VideoPopup = ({ videoUrl, videoRef, callAccepted, userOpenCamera }) => {
    const localVideoRef = useRef(null);
    const videoPromoRef = useRef(null);
    const [isPromoVideoActive, setIsPromoVideoActive] = useState(true);

    useEffect(() => {
        const enterFullScreen = (ref) => {
            const requestFullScreen = ref.current.requestFullscreen || ref.current.mozRequestFullScreen || ref.current.webkitRequestFullscreen || ref.current.msRequestFullscreen;
            if (requestFullScreen) {
                requestFullScreen.call(ref.current);
            }
        };

        const setVideoUrl = (url) => {
            videoPromoRef.current.src = '';

            if (url) {
                videoPromoRef.current.src = url;
            }
        };

        const playVideo = async () => {
            if (videoPromoRef.current && !callAccepted) {
                setVideoUrl(videoUrl);
                videoPromoRef.current.muted = true;
                try {
                    await videoPromoRef.current.play();
                    enterFullScreen(videoPromoRef);
                } catch (error) {
                    console.error("El video no pudo reproducirse automáticamente:", error);
                }
            } else if (callAccepted) {
                if (videoPromoRef.current) {
                    videoPromoRef.current.pause();
                    videoPromoRef.current.src = '';
                }
            }
        };

        if (videoPromoRef.current) {
            if (videoPromoRef.current.paused) {
                playVideo();
            }
        }

    }, [videoUrl, videoRef, callAccepted]);

    useEffect(() => {
        if (!callAccepted) {
            setIsPromoVideoActive(true);
        }
        if (callAccepted && userOpenCamera) {
            setIsPromoVideoActive(false);
        }
        if (callAccepted && !userOpenCamera) {
            setIsPromoVideoActive(true);
        }
        if (!callAccepted && isPromoVideoActive) {
            videoPromoRef.current.src = videoUrl;
            videoPromoRef.current.muted = true;
            videoPromoRef.current.play();
        }
        if (callAccepted && isPromoVideoActive && !userOpenCamera) {
            videoPromoRef.current.src = videoUrl;
            videoPromoRef.current.muted = true;
            videoPromoRef.current.play();
        }
        if (callAccepted && !isPromoVideoActive) {
            localVideoRef.current.srcObject = videoRef?.current?.srcObject;
        }
    }, [callAccepted, isPromoVideoActive, videoRef, videoUrl, userOpenCamera]);

    return (
      <ThemeProvider theme={theme}>
          <div
              style={{
                  position: "relative",
                  width: "100vw",
                  height: "100vh",
              }}
          >
              {callAccepted && !isPromoVideoActive && (
                  <video
                      playsInline
                      muted
                      ref={localVideoRef}
                      autoPlay
                      style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                          display: userOpenCamera ? 'block' : 'none'
                      }}
                  />
              )}
              {(isPromoVideoActive || !userOpenCamera) && (
                  <video
                      loop={true}
                      controls={false}
                      ref={videoPromoRef}
                      style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          width: "100%",
                          height: "100%",
                      }}
                  />
              )}
          </div>
      </ThemeProvider>
    );
};

export default VideoPopup;