import * as React from 'react';
import { useContext } from "react";
import { Tooltip } from "@mui/material";
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import LogoutIcon from '@mui/icons-material/Logout';
import { MainListItems } from '../../views/maintenance/ListItems.view';
import DashboardView from "../../views/maintenance/Dashboard.view";
import UsersView from "../../views/maintenance/Users.view";
import SettingsView from "../../views/maintenance/Settings.view";
import { AuthContext } from "../../context/AuthContext";
import CopyrightFooter from '../Shared/CopyrightFooter';

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
    shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    ...(open && {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
        transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        '& .MuiDrawer-paper': {
            position: 'relative',
            whiteSpace: 'nowrap',
            width: drawerWidth,
            transition: theme.transitions.create('width', {
                easing: theme.transitions.easing.sharp,
                duration: theme.transitions.duration.enteringScreen,
            }),
            boxSizing: 'border-box',
            ...(!open && {
                overflowX: 'hidden',
                transition: theme.transitions.create('width', {
                    easing: theme.transitions.easing.sharp,
                    duration: theme.transitions.duration.leavingScreen,
                }),
                width: theme.spacing(7),
                [theme.breakpoints.up('sm')]: {
                    width: theme.spacing(9),
                },
            }),
        },
    }),
);

const defaultTheme = createTheme({
    palette: {
        background: {
            default: '#f0f4f8',
        },
        secondary: {
            main: '#fe0000'
        }
    }
});

export default function Maintenance() {
    const { user, logout } = useContext(AuthContext);
    const [open, setOpen] = React.useState(true);
    const [selectedIndex, setSelectedIndex] = React.useState(0);
    const [sectionsSelected] = React.useState([
        { id: 0, selected: true, name: "Dashboard", view: <DashboardView /> },
        { id: 1, selected: false, name: "Usuarios", view: <UsersView /> },
        { id: 2, selected: false, name: "Configuraciones", view: <SettingsView /> }
    ]);
    const [currentSectionName, setCurrentSectionName] = React.useState("Dashboard");
    const [currentSectionView, setCurrentSectionView] = React.useState(sectionsSelected[0].view);

    React.useEffect(() => {
        setCurrentSectionName(sectionsSelected[selectedIndex].name);
        setCurrentSectionView(sectionsSelected[selectedIndex].view);
    }, [selectedIndex, sectionsSelected]);

    const toggleDrawer = () => {
        setOpen(!open);
    };
    const handleListItemClick = index => {
        setSelectedIndex(index);
    };

    return (
        <ThemeProvider theme={defaultTheme}>
            <Box sx={{ display: 'flex' }}>
                <CssBaseline />
                <AppBar position="absolute" open={open} sx={{ backgroundColor: defaultTheme.palette.secondary.main }}>
                    <Toolbar
                        sx={{
                            pr: '24px', // keep right padding when drawer closed
                        }}
                    >
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawer}
                            sx={{
                                marginRight: '36px',
                                ...(open && { display: 'none' }),
                            }}
                        >
                            <MenuIcon />
                        </IconButton>
                        <Typography
                            component="h1"
                            variant="h6"
                            color="inherit"
                            noWrap
                            sx={{ flexGrow: 2 }}
                        >
                            {currentSectionName}
                        </Typography>
                        <Box sx={{flexGrow: 1, display: "flex", justifyContent: "center"}}>
                            <img src="./windows11/LargeTile.scale-100.png" alt="Logo" style={{height: 40}}/>
                            <Typography
                                component="h1"
                                variant="h6"
                                color="inherit"
                                noWrap
                                sx={{ flexGrow: 1, marginTop: 1 }}
                            >
                                | Guardia Virtual
                            </Typography>
                        </Box>
                        <Box sx={{flexGrow: 1, display: "flex", justifyContent: "flex-end"}}>
                            <Typography variant="body1" sx={{ marginTop: 1, marginRight: 1 }}>Bienvenido {user.name}!</Typography>
                            <Tooltip title="Cerrar sesion">
                                <IconButton color="inherit" onClick={logout}>
                                    <LogoutIcon />
                                </IconButton>
                            </Tooltip>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer variant="permanent" open={open}>
                    <Toolbar
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'flex-end',
                            px: [1],
                        }}
                    >
                        <IconButton onClick={toggleDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    </Toolbar>
                    <Divider />
                    <List component="nav">
                        <MainListItems selectedIndex={selectedIndex} onListItemClick={handleListItemClick} />
                    </List>
                </Drawer>
                <Box
                    component="main"
                    sx={{
                        backgroundColor: (theme) =>
                            theme.palette.mode === 'light'
                                ? theme.palette.grey[100]
                                : theme.palette.grey[900],
                        flexGrow: 1,
                        height: '100vh',
                        overflow: 'auto',
                    }}
                >
                    <Toolbar />
                    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
                        <Grid container spacing={3}>
                            {currentSectionView}
                        </Grid>
                        <CopyrightFooter sx={{ pt: 4 }} />
                    </Container>
                </Box>
            </Box>
        </ThemeProvider>
    );
}