import React from 'react';
import { IconButton } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PasswordRoundedIcon from '@mui/icons-material/PasswordRounded';
import DataTable from "../../shared/DataTable.fragment";

const UserTable = ({ users, onEditUser, onDeleteUser, onChangePw }) => {
    const columns = [
        { field: 'id', headerName: 'ID', width: 70 },
        { field: 'name', headerName: 'Nombre', width: 200 },
        { field: 'email', headerName: 'Email', width: 250 },
        { field: 'type', headerName: 'Rol', width: 150 },
        {
            field: 'actions',
            headerName: 'Acciones',
            width: 150,
            renderCell: (params) => (
                <>
                    <IconButton onClick={() => onChangePw(params.row)}>
                        <PasswordRoundedIcon />
                    </IconButton>
                    <IconButton onClick={() => onEditUser(params.row)}>
                        <EditIcon />
                    </IconButton>
                    <IconButton onClick={() => onDeleteUser(params.row)}>
                        <DeleteIcon />
                    </IconButton>
                </>
            ),
        },
    ];

    return (
        <DataTable rows={users} columns={columns} />
    );
};

export default UserTable;