import React from "react";
import { IconButton } from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import DataTable from "../../shared/DataTable.fragment";

const SettingsTable = ({ settings, onEditSetting, onDeleteSetting }) => {
    const columns = [
        { field: "id", headerName: "ID", width: 70 },
        { field: "name_id", headerName: "Nombre", width: 200 },
        { field: "value", headerName: "Valor", width: 250 },
        { field: "users_to_apply", headerName: "Usuarios", width: 70 },
        { field: "apply_to", headerName: "Aplica a", width: 250 },
        {
            field: "actions",
            headerName: "Acciones",
            width: 150,
            renderCell: (params) => (
                <>
                <IconButton onClick={() => onEditSetting(params.row)}>
                    <EditIcon />
                </IconButton>
                <IconButton onClick={() => onDeleteSetting(params.row)}>
                    <DeleteIcon />
                </IconButton>
                </>
            ),
        },
    ];

    return <DataTable rows={settings} columns={columns} />;
};

export default SettingsTable;