import React, { useState, useEffect } from "react";
import { isEmpty } from "lodash";
import { Container, Grid, Paper, Tooltip, IconButton, Alert } from '@mui/material';
import { createTheme, ThemeProvider } from "@mui/material/styles";
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import Snackbar from '@mui/material/Snackbar';
import SettingsTable from "../../fragments/maintenance/settings/SettingsTable.fragment";
import SettingsFormDialog from "../../fragments/maintenance/settings/SettingsFormDialog.fragment";
import ConfirmationDialog from "../../fragments/shared/ConfirmDialog.fragment";
import { getSettings, updateSetting, createSetting, deleteSetting } from "../../api/settings.service";
import { getUsers } from "../../api/users.service";

const theme = createTheme(
    {
        palette: {
            primary: { main: '#fe0000' },
        },
    },
);

const SettingsView = () => {
    const [settings, setSettings] = useState([]);
    const [users, setUsers] = useState([]);
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [selectedSetting, setSelectedSetting] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [emptyForm, setEmptyForm] = useState(false);

    useEffect(() => {
        // Fetch users and settings in parallel
        const getUsersPromise = getUsers();
        const getSettingsPromise = getSettings();

        Promise.allSettled([getUsersPromise, getSettingsPromise])
            .then((results) => {
                const usersData = results[0].status === 'fulfilled' ? results[0].value?.data || [] : [];
                const settingsData = results[1].status === 'fulfilled' ? results[1].value?.data || [] : [];
                setUsers(usersData);
                setSettings(settingsData);
            })
            .catch((error) => {
                setSnackbarSeverity('error');
                setSnackbarMessage(error?.message || 'Error al obtener los datos');
                setOpenSnackbar(true);
            });
    }, []);

    const handleAddSetting = () => {
        setSelectedSetting(null);
        setOpenFormDialog(true);
    };

    const handleEditSetting = (setting) => {
        setSelectedSetting(setting);
        setOpenFormDialog(true);
    };

    const handleDeleteSetting = (setting) => {
        setSelectedSetting(setting);
        setOpenConfirmationDialog(true);
    }

    const handleSaveSetting = (setting) => {
        let settingToUpdate = {};
        const sameSettingFound = settings.find((s) => s.name_id === setting.nameId && s.value === setting.value);

        if (selectedSetting || !isEmpty(sameSettingFound)) {
            settingToUpdate = selectedSetting ? {
                ...selectedSetting,
                ...setting
            } : {
            ...setting,
                    users_to_apply: [
                    ...sameSettingFound.users_to_apply,
                    ...setting.users_to_apply
                ]
            };
            const idToUpdate = !isEmpty(sameSettingFound) ? sameSettingFound.id : selectedSetting.id;

            updateSetting(idToUpdate, settingToUpdate)
                .then((data) => {
                    setSettings((prevSettings) => {
                        const index = prevSettings.findIndex((s) => s.id === idToUpdate);
                        const newSettings = [...prevSettings];
                        const usersToApply = users.filter((user) => data?.data?.users_to_apply.includes(user.id));
                        const usersNames = usersToApply.map((user) => user.name);
                        const userNamesStr = usersNames.join(', ');
                        const newItem = { ...data?.data, name_id: data?.data?.nameId, apply_to: userNamesStr };
                        newSettings[index] = newItem || {};
                        return newSettings;
                    });
                    setSnackbarSeverity('success');
                    setSnackbarMessage('Configuración actualizada');
                    setOpenSnackbar(true);
                    setOpenFormDialog(false);
                })
                .catch((error) => {
                    setSnackbarSeverity('error');
                    setSnackbarMessage(error?.message || 'Error al actualizar la configuración');
                    setOpenSnackbar(true);
                });
        } else {
            const newSetting = {
                ...setting
            };

            createSetting(newSetting)
                .then((data) => {
                    const users_to_apply = data?.data?.users_to_apply || [];
                    const usersToApply = users.filter((user) => users_to_apply.includes(user.id));
                    const usersNames = usersToApply.map((user) => user.name);
                    const userNamesStr = usersNames.join(', ');

                    const newData = { ...data?.data, name_id: data?.data?.nameId, apply_to: userNamesStr };
                    setSettings((prevSettings) => [...prevSettings, newData || {}]);
                    setSnackbarSeverity('success');
                    setSnackbarMessage('Configuración creada');
                    setOpenSnackbar(true);
                    setOpenFormDialog(false);
                    setEmptyForm(true);
                })
                .catch((error) => {
                    setSnackbarSeverity('error');
                    setSnackbarMessage(error?.message || 'Error al crear la configuración');
                    setOpenSnackbar(true);
                });
        }
    };

    const handleDeleteSettingConfirm = () => {
        deleteSetting(selectedSetting.id)
            .then(() => {
                setSettings((prevSettings) => prevSettings.filter((s) => s.id !== selectedSetting.id));
                setSnackbarSeverity('success');
                setSnackbarMessage('Configuración eliminada');
                setOpenSnackbar(true);
                setOpenConfirmationDialog(false);
            })
            .catch((error) => {
                setSnackbarSeverity('error');
                setSnackbarMessage(error?.message || 'Error al eliminar la configuración');
                setOpenSnackbar(true);
            });
    };

    const handleCloseSnackBar = () => {
        setOpenSnackbar(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Container sx={{ marginTop: 5 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 1 }}>
                            <Grid container spacing={2} justifyContent="space-between">
                                <Grid item>
                                    <Alert severity="warning">
                                        <strong>Nota:</strong> Las configuraciones son valores que se utilizan en la aplicación para definir comportamientos o valores predeterminados.
                                    </Alert>
                                </Grid>
                                <Grid item>
                                    <Tooltip title={"Agregar configuracion"}>
                                        <IconButton
                                            color="primary"
                                            onClick={handleAddSetting}
                                        >
                                            <AddCircleRoundedIcon fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <SettingsTable
                            settings={settings}
                            onEditSetting={handleEditSetting}
                            onDeleteSetting={handleDeleteSetting}
                        />
                    </Grid>
                </Grid>

                <SettingsFormDialog
                    open={openFormDialog}
                    setting={selectedSetting}
                    users={users}
                    onSave={handleSaveSetting}
                    onClose={() => setOpenFormDialog(false)}
                    emptyForm={emptyForm}
                />
                <ConfirmationDialog
                    open={openConfirmationDialog}
                    title="Eliminar configuración"
                    message={`¿Estás seguro que deseas eliminar esta configuración (${selectedSetting?.name_id})?`}
                    onConfirm={handleDeleteSettingConfirm}
                    onClose={() => setOpenConfirmationDialog(false)}
                />
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                    <Alert
                        onClose={handleCloseSnackBar}
                        severity={snackbarSeverity}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </ThemeProvider>
    );
};

export default SettingsView;