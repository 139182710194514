import React, { useState, useEffect } from 'react';
import { Container, Grid, Paper, Tooltip, IconButton, Alert } from '@mui/material';
import AddCircleRoundedIcon from '@mui/icons-material/AddCircleRounded';
import { getUsers, updateUser, deleteUser, createUser } from '../../api/users.service';
import { changePassword } from "../../api/auth.service";
import UserTable from '../../fragments/maintenance/users/UserTable.fragment';
import UserFormDialog from '../../fragments/maintenance/users/UserFormDialog.fragment';
import ConfirmationDialog from '../../fragments/shared/ConfirmDialog.fragment';
import ChangePwFormDialog from "../../fragments/maintenance/users/ChangePwFormDialog.fragment";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import Snackbar from '@mui/material/Snackbar';


const theme = createTheme(
    {
        palette: {
            primary: { main: '#fe0000' },
        },
    },
);
const UsersView = () => {
    const [users, setUsers] = useState([]);
    const [openFormDialog, setOpenFormDialog] = useState(false);
    const [openConfirmationDialog, setOpenConfirmationDialog] = useState(false);
    const [openChangePwDialog, setOpenChangePwDialog] = useState(false);
    const [selectedUser, setSelectedUser] = useState(null);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [emptyForm, setEmptyForm] = useState(false);

    useEffect(() => {
        getUsers()
            .then((data) => setUsers(data?.data || []))
            .catch((error) => {
                setSnackbarSeverity('error');
                setSnackbarMessage(error?.message || 'Error al obtener los usuarios');
                setOpenSnackbar(true);
            });
    }, []);

    const handleAddUser = () => {
        setSelectedUser(null);
        setOpenFormDialog(true);
    };

    const handleEditUser = (user) => {
        setSelectedUser(user);
        setOpenFormDialog(true);
    };

    const handleChangePw = (user) => {
        setSelectedUser(user);
        setOpenChangePwDialog(true);
    };

    const handleDeleteUser = (user) => {
        setSelectedUser(user);
        setOpenConfirmationDialog(true);
    };

    const handleSaveUser = (user) => {
        if (selectedUser) {
            const userToUpdate = {
                name: user.name,
                email: user.email,
                type: user.type,
            };

            updateUser(selectedUser.id, userToUpdate)
                .then((response) => {
                    const data = response?.data;
                    const updatedUser = {
                        id: data?.id,
                        name: data?.name,
                        email: data?.email,
                        type: data?.type,
                    };
                    setUsers(users.map(u => (u.id === user.id ? updatedUser : u)));
                    setSnackbarSeverity('success');
                    setSnackbarMessage('Usuario actualizado correctamente');
                    setOpenSnackbar(true);
                    setOpenFormDialog(false);
                })
                .catch((error) => {
                    setSnackbarSeverity('error');
                    setSnackbarMessage(error?.message || 'Error al actualizar el usuario');
                    setOpenSnackbar(true);
                });
        } else {
            const newUser = {
                name: user.name,
                email: user.email,
                password: user.password,
                type: user.type,
            };

            createUser(newUser)
                .then((response) => {
                    const data = response?.data;
                    setUsers([...users, { ...data }]);
                    setSnackbarSeverity('success');
                    setSnackbarMessage('Usuario creado correctamente');
                    setOpenSnackbar(true);
                    setOpenFormDialog(false);
                    setEmptyForm(true);
                })
                .catch((error) => {
                    setSnackbarSeverity('error');
                    setSnackbarMessage(error?.message || 'Error al crear el usuario');
                    setOpenSnackbar(true);
                });
        }
    };

    const handlePasswordChange = (user) => {
        if (selectedUser) {
            const credentials = {
                id: user.id,
                newPassword: user.password,
            };

            changePassword(credentials)
                .then(() => {
                    setSnackbarSeverity('success');
                    setSnackbarMessage('Contraseña actualizada correctamente');
                    setOpenSnackbar(true);
                    setOpenChangePwDialog(false);
                })
                .catch((error) => {
                    setSnackbarSeverity('error');
                    setSnackbarMessage(error?.message || 'Error al actualizar la contraseña');
                    setOpenSnackbar(true);
                });
        }
    };

    const handleConfirmDeleteUser = () => {
        deleteUser(selectedUser.id)
            .then(() => {
                setUsers(users.filter(u => u.id !== selectedUser.id));
                setSnackbarSeverity('success');
                setSnackbarMessage('Usuario eliminado correctamente');
                setOpenSnackbar(true);
                setOpenConfirmationDialog(false);
            })
            .catch((error) => {
                setSnackbarSeverity('error');
                setSnackbarMessage(error?.message || 'Error al eliminar el usuario');
                setOpenSnackbar(true);
            });
    };

    const handleCloseSnackBar = () => {
        setOpenSnackbar(false);
    };

    return (
        <ThemeProvider theme={theme}>
            <Container sx={{ marginTop: 5 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Paper sx={{ p: 1 }}>
                            <Grid container spacing={2} justifyContent="space-between">
                                <Grid item>
                                    <Alert severity="warning">
                                        Cualquier acción que realices en esta sección es irreversible, por lo que te recomendamos tener cuidado.
                                    </Alert>
                                </Grid>
                                <Grid item>
                                    <Tooltip title={"Agregar usuario"}>
                                        <IconButton
                                            color="primary"
                                            onClick={handleAddUser}
                                        >
                                            <AddCircleRoundedIcon fontSize="large" />
                                        </IconButton>
                                    </Tooltip>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                    <Grid item xs={12}>
                        <UserTable
                            users={users}
                            onEditUser={handleEditUser}
                            onDeleteUser={handleDeleteUser}
                            onChangePw={handleChangePw}
                        />
                    </Grid>
                </Grid>

                <UserFormDialog
                    open={openFormDialog}
                    user={selectedUser}
                    onSave={handleSaveUser}
                    onClose={() => setOpenFormDialog(false)}
                    emptyForm={emptyForm}
                />
                <ConfirmationDialog
                    open={openConfirmationDialog}
                    title="Eliminar usuario"
                    message={`¿Estás seguro de que deseas eliminar a ${selectedUser?.name}?`}
                    onConfirm={handleConfirmDeleteUser}
                    onClose={() => setOpenConfirmationDialog(false)}
                />
                <ChangePwFormDialog
                    open={openChangePwDialog}
                    user={selectedUser}
                    onSave={handlePasswordChange}
                    onClose={() => setOpenChangePwDialog(false)}
                />
                <Snackbar open={openSnackbar} autoHideDuration={6000} onClose={handleCloseSnackBar}>
                    <Alert
                        onClose={handleCloseSnackBar}
                        severity={snackbarSeverity}
                        variant="filled"
                        sx={{ width: '100%' }}
                    >
                        {snackbarMessage}
                    </Alert>
                </Snackbar>
            </Container>
        </ThemeProvider>
    );
};

export default UsersView;