import React from "react";
import { Typography } from "@mui/material";

import packageJson from "../../../package.json";

function CopyrightFooter(props) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props}>
            {`Version ${packageJson.version}`}
        </Typography>
    );
}

export default CopyrightFooter;