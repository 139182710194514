import * as React from 'react';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardIcon from '@mui/icons-material/Dashboard';
import SettingsIcon from '@mui/icons-material/Settings';
import PeopleIcon from '@mui/icons-material/People';


const elementSelectedStyle = {
    '&.Mui-selected': {
        backgroundColor: "#DADADA",
        '&:hover': {
            backgroundColor: "#FABFBF",
            color: "black",
            '& .MuiListItemIcon-root': {
                color: "black",
            }
        },
    },
};

export function MainListItems({ selectedIndex, onListItemClick }) {
    return (
        <React.Fragment>
            <ListItemButton
                sx={{...elementSelectedStyle}}
                selected={selectedIndex === 0}
                onClick={() => onListItemClick(0)}
            >
                <ListItemIcon>
                    <DashboardIcon />
                </ListItemIcon>
                <ListItemText primary="Dashboard" />
            </ListItemButton>
            <ListItemButton
                sx={{...elementSelectedStyle}}
                selected={selectedIndex === 1}
                onClick={() => onListItemClick(1)}
            >
                <ListItemIcon>
                    <PeopleIcon />
                </ListItemIcon>
                <ListItemText primary="Usuarios" />
            </ListItemButton>
            <ListItemButton
                sx={{...elementSelectedStyle}}
                selected={selectedIndex === 2}
                onClick={() => onListItemClick(2)}
            >
                <ListItemIcon>
                    <SettingsIcon />
                </ListItemIcon>
                <ListItemText primary="Configuraciones" />
            </ListItemButton>
        </React.Fragment>
    );
}