import AxiosInstance from "./axios.instance";
import axios from "axios";

const {
    REACT_APP_AUTH_LOGIN,
    REACT_APP_API_BASE,
    REACT_APP_AUTH_REFRESH_TOKEN,
    REACT_APP_AUTH_CHANGE_PASSWORD
} = process.env;
const axiosInstance = new AxiosInstance(REACT_APP_API_BASE, REACT_APP_AUTH_REFRESH_TOKEN);
export const login = async credentials => {
    try {
        const response = await axios.post(`${REACT_APP_API_BASE}${REACT_APP_AUTH_LOGIN}`, {
            ...credentials
        });

        const data = response?.data;
        const success = data?.success;

        if (!success) {
            const errorMessage = data?.errorMessage;
            throw new Error(errorMessage || 'Login failed');
        }

        return data;
    } catch (error) {
        throw error;
    }
}

export const changePassword = async credentials => {
    try {
        const response = await axiosInstance.patch(REACT_APP_AUTH_CHANGE_PASSWORD, {
            ...credentials
        });

        const data = response?.data;
        const success = data?.success;

        if (!success) {
            const errorMessage = data?.errorMessage;
            throw new Error(errorMessage || 'Change password failed');
        }

        return data;
    } catch (error) {
        throw error;
    }
}