export const openVideoPopup = () => {
    const popup = window.open('', '', 'width=800,height=600)');

    // Set popup window to top right corner
    popup.moveTo(window.screen.width - 800, 0);
    popup.resizeTo(800, 400);

    popup.document.title = "Video Popup";
    popup.document.body.style.margin = 0;
    popup.document.body.style.padding = 0;
    popup.document.body.style.overflow = 'hidden';

    return popup;
};