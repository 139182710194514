import React, {useState, useContext, useEffect} from 'react';
import {
    TextField,
    Button,
    Box,
    Typography,
    Container,
    Avatar,
    CssBaseline,
    AppBar,
    Toolbar,
    Snackbar,
    Alert
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext';
import CopyrightFooter from "../Shared/CopyrightFooter";

const theme = createTheme({
    palette: {
        background: {
            default: '#f0f4f8',
        },
        secondary: {
            main: '#fe0000'
        }
    },
});

const Login = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const navigate = useNavigate();
    const { login, loading, error } = useContext(AuthContext);
    const [openAlert, setOpenAlert] = useState(false);
    const [alertMessage, setAlertMessage] = useState('');
    const [alertSeverity] = useState('error');

    useEffect(() => {
        if (error) {
            setAlertMessage(error?.response?.data?.errorMessage || error?.message);
            setOpenAlert(true);
        }
    }, [error]);

    const handleSubmit = (event) => {
        event.preventDefault();

        login({ email, password });
        navigate('/home');
    };

    return (
        <ThemeProvider theme={theme}>
            <Container component="main" maxWidth="xs" sx={{ backgroundColor: theme.palette.background.default, padding: 4, borderRadius: 2, mt: 8 }}>
                <CssBaseline />
                <AppBar position="fixed" sx={{ backgroundColor: '#fe0000' }}>
                    <Toolbar>
                        <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'center' }}>
                            <img src="./santander_rojo.png" alt="Logo" style={{ height: 40 }} />
                        </Box>
                    </Toolbar>
                </AppBar>
                <Box
                    sx={{
                        marginTop: 8,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'center',
                    }}
                >
                    <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Iniciar Sesión
                    </Typography>
                    <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                        <TextField
                            disabled={loading}
                            margin="normal"
                            required
                            fullWidth
                            id="email"
                            label="Correo Electrónico"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                        />
                        <TextField
                            disabled={loading}
                            margin="normal"
                            required
                            fullWidth
                            name="password"
                            label="Contraseña"
                            type="password"
                            id="password"
                            autoComplete="current-password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                        />
                        <Button
                            disabled={loading}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{
                                mt: 3,
                                mb: 2,
                                bgcolor: 'secondary.main',
                                '&:hover': {
                                    backgroundColor: '#ec0000',
                                    color: 'background.default'
                                }
                            }}
                        >
                            {loading ? 'Iniciando...' : 'Iniciar sesion'}
                        </Button>
                        <Snackbar open={openAlert} autoHideDuration={6000} onClose={() => setOpenAlert(false)}>
                            <Alert
                                onClose={() => setOpenAlert(false)}
                                severity={alertSeverity}
                                variant="filled"
                                sx={{ width: '100%' }}
                            >
                                {alertMessage}
                            </Alert>
                        </Snackbar>
                    </Box>
                    <Box sx={{ mt: 5 }}>
                        <CopyrightFooter sx={{ pt: 4 }} />
                    </Box>
                </Box>
            </Container>
        </ThemeProvider>
    );
};

export default Login;